/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import scrollTo from "gatsby-plugin-smoothscroll";

import "@stylesComponents/Header.scss";

import Logo from "@images/logo.svg";
import MenuIcon from "@icons/menu.png";
import CloseIcon from "@icons/close.png";

import Language from "@components/Language";

export default function Header({ pathname }) {
  const { t } = useTranslation("translation");
  const [mobile, setMobile] = useState(false);
  const handleNavMobile = () => {
    const bodyFixed = document.getElementsByTagName("body")[0];
    bodyFixed.style.overflow = !mobile ? "hidden" : "";
    setMobile(!mobile);
  };

  return (
    <nav className="nav">
      <div className="nav__container">
        <Link to="/">
          <img src={Logo} alt="logo" className="nav__logo" />
        </Link>
        <Link to="/" className="nav__item-mobile" onClick={mobile ? handleNavMobile : ""}>
          <img src={Logo} alt="logo" className="nav__item-mobile" />
        </Link>
        <ul className="nav__links">
          <li className="nav__item">
            <Language />
          </li>
          <li className="nav__item">
            {pathname === "/" || pathname === "/en/" ? (
              <button
                type="button"
                onClick={() => scrollTo("#courses")}
                className="nav__link"
              >
                {t("courses")}
              </button>
            ) : (
              <Link
                to="/#courses"
                onClick={() => scrollTo("#courses")}
                className="nav__link"
              >
                {t("courses")}
              </Link>
            )}
          </li>
          <li className="nav__item">
            {pathname === "/" || pathname === "/en/" ? (
              <button
                type="button"
                onClick={() => scrollTo("#contact")}
                className="nav__link"
              >
                {t("contact")}
              </button>
            ) : (
              <Link
                to="/#contact"
                onClick={() => scrollTo("#contact")}
                className="nav__link"
              >
                {t("contact")}
              </Link>
            )}
          </li>
          <li className="nav__item">
            <a
              href="https://clientzone.nftslearn.com/signin"
              target="_blank"
              rel="noreferrer"
              className="nav__button nav__button--fill"
            >
              {t("login")}
            </a>
          </li>
          <li className="nav__item">
            <a
              href="https://clientzone.nftslearn.com/signup"
              target="_blank"
              rel="noreferrer"
              className="nav__button nav__button--transparent"
            >
              {t("register")}
            </a>
          </li>
        </ul>
        <button
          type="button"
          onClick={handleNavMobile}
          className="nav__open"
        >
          <img src={mobile ? CloseIcon : MenuIcon} alt="Menu" className="nav__icon" />
        </button>
      </div>
      <div className={`nav__mobile${mobile ? " nav__mobile--active" : ""}`}>
        <ul className="nav__mobile-links">
          <li className="nav__item">
            {pathname === "/" || pathname === "/en/" ? (
              <button
                type="button"
                onClick={() => { scrollTo("#courses"); handleNavMobile(); }}
                className="nav__link"
              >
                {t("courses")}
              </button>
            ) : (
              <Link
                to="/#courses"
                onClick={() => { scrollTo("#courses"); handleNavMobile(); }}
                className="nav__link"
              >
                {t("courses")}
              </Link>
            )}

          </li>
          <li className="nav__item">
            {pathname === "/" || pathname === "/en/" ? (
              <button
                type="button"
                onClick={() => { scrollTo("#contact"); handleNavMobile(); }}
                className="nav__link"
              >
                {t("contact")}
              </button>
            ) : (
              <Link
                to="/#contact"
                onClick={() => { scrollTo("#contact"); handleNavMobile(); }}
                className="nav__link"
              >
                {t("contact")}
              </Link>
            )}
          </li>
          <li className="nav__item">
            <a
              href="https://clientzone.nftslearn.com/signin"
              target="_blank"
              rel="noreferrer"
              className="nav__button nav__button--fill"
            >
              {t("login")}
            </a>
          </li>
          <li className="nav__item">
            <a
              href="https://clientzone.nftslearn.com/signup"
              target="_blank"
              rel="noreferrer"
              className="nav__button nav__button--transparent"
            >
              {t("register")}
            </a>
          </li>
          <li className="nav__item" onClick={mobile ? handleNavMobile : ""}>
            <Language />
          </li>
        </ul>
      </div>
    </nav>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
