/* eslint-disable max-len */
import { useTranslation } from "gatsby-plugin-react-i18next";
// import useGetCountry from "./useGetCountry";

export default function useCompany(country = "both") {
  const { t } = useTranslation("translation");
  // const { code } = useGetCountry();

  const MX = true;
  // const MX = code === "MX";
  // const CR = code === "CR";
  // const CO = code === "CO";

  // const directionMX = "Florencia 57, Col. Juarez, C.P. 06600, Col. Juarez, Alcaldía Cuahutemoc";
  // const directionCR = "Alajuela, Costa Rica. \n";
  // const companyCR = CR ? `${t("company")} Casidy Metacominicación CR. Sociedad Limitada ${directionCR}` : null;
  // const companyMX = MX ? `${t("company")} Digital Metasofware S.A. de C.V.`: null;
  // const companyMX = MX
  //   ? `${t("company")} PARNE MARKET S.A. de C.V. \n ` // ${directionMX}`
  //   : null;
  // const companyCO = CO ? `${t("company")} Marketing Group CO S.A.S` : null;

  // if (country === "both") return companyCO ?? companyMX ?? "";
  // if (country === "CO") return companyCO ?? "";
  // return companyMX; // companyCR ?? "";
  return "";
}
