import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useCompany } from "@hooks";

import "@stylesComponents/Footer.scss";

import Logo from "@images/logo.svg";

export default function Footer({ pathname }) {
  const { t } = useTranslation("translation");
  const company = useCompany();

  return (
    <footer className="footer">
      <div className="footer__container">
        <Link to="/">
          <img src={Logo} alt="Logo" className="footer__logo" />
        </Link>
        <p className="footer__copyright">
          <b>©NFTs Learn</b>
          {t("rights", { company })}
        </p>
        <ul className="footer__list">
          <li className="footer__item">
            {pathname === "/" || pathname === "/en/" ? (
              <button
                type="button"
                onClick={() => scrollTo("#courses")}
                className="nav__link"
              >
                {t("courses")}
              </button>
            ) : (
              <Link
                to="/#courses"
                onClick={() => scrollTo("#courses")}
                className="nav__link"
              >
                {t("courses")}
              </Link>
            )}
          </li>
          <li className="footer__item">
            <Link to="/terms" className="footer__text">
              {t("terms")}
            </Link>
          </li>
          <li className="footer__item">
            <Link to="/about" className="footer__text">
              {t("about")}
            </Link>
          </li>
          <li className="footer__item">
            {pathname === "/" || pathname === "/en/" ? (
              <button
                type="button"
                onClick={() => scrollTo("#contact")}
                className="nav__link"
              >
                {t("contact")}
              </button>
            ) : (
              <Link
                to="/#contact"
                onClick={() => scrollTo("#contact")}
                className="nav__link"
              >
                {t("contact")}
              </Link>
            )}
          </li>
          <li className="footer__item">
            <Link to="/privacy" className="footer__text">
              {t("privacy")}
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
