import Header from "@components/Header";
import Footer from "@components/Footer";

import "@styles";
import "@stylesComponents/Layout.scss";

function Layout({ children, pathname = "" }) {
  /* Effect to add a script */
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.textContent = ``;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <main className="layout">
      <Header pathname={pathname} />
      {children}
      <Footer pathname={pathname} />
      <button
        type="button"
        id="scroll-btn"
        className="sidebar-btns"
        title="Scroll to top"
      />
    </main>
  );
}

export default Layout;
